<template>
  <div>
    <HeadComponent/>
    <div class="index">
      <div class="section1">
        <div class="bg"><img src="images/bg1.png" alt=""></div>
        <div class="content">
          <div class="txt1">BNBPUMP</div>
          <div class="txt2">
            <p>Wolcome to </p>
            <p>BNBPUMP</p>
            <p>MEME Launchpad on BSC</p>
          </div>
          <a href="#" class="more1">How it works?</a>
          <div class="dec">You can search for your favorite TOKEN on BNBPUMP and purchase it, or you can quickly activate the token</div>
          <a href="#" class="more"></a>
        </div>
      </div>
      <div class="section2">
        <div class="wrap">
          <div class="choose">
            <div class="left">
              <h2 class="h2tit">Launched Time</h2>
              <dl>
                <dd>Launched Time</dd>
                <dd>Trading Volume</dd>
                <dd>24H Price Increase</dd>
              </dl>
            </div>
            <div class="right">
              <input type="text" placeholder="Search for token" class="words">
              <input type="button" value="" class="send">
            </div>
          </div>
          <div class="list">
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
            <div class="item">
              <div class="imgbox"><a href="#"><img src="images/pic.jpg" alt=""></a></div>
              <div class="content">
                <a href="#">
                  <span class="title">Created by: 0x1a52...89c2</span>
                  <span class="title2">BNBPUMP（$BNBP)</span>
                  <span class="dec">Make DSADAD SADSADSADASDgreat again sun pump is backing</span>
                  <span class="per">
                Market Cap: <b >$ 10k (10%)</b>
              </span>
                  <span class="line"><i></i></span>
                </a>
              </div>
            </div>
          </div>
          <div class="pagesize">
            <a href="#" class="border">《</a>
            <a href="#" class="border"><</a>
            <a href="#" class="current">1</a>
            <a href="#">2</a>
            <a href="#">3</a>
            <span>...</span>
            <a href="#">6</a>
            <a href="#" class="border">></a>
            <a href="#" class="border">》</a>
          </div>
        </div>
      </div>
      <div class="section3">
        <div class="title"><b>Support</b></div>
        <div class="list">
          <div class="item">
            <div class="icon"><img src="images/cir1.png" alt=""></div>
            <div class="content">
              <div class="title">PancakeSwap</div>
              <div class="dec">PancakeSwap is the leading decentralized exchange on BNB Smart Chain, with the highest trading volumes in the market</div>
            </div>
          </div>
          <div class="item">
            <div class="icon"><img src="images/cir2.png" alt=""></div>
            <div class="content">
              <div class="title">BNB Smart Chain</div>
              <div class="dec">BNB Smart Chain is a high-performance blockchain platform designed to enable the development of scalable and user-friendly decentralized applications (DApps), particularly in the decentralized finance (DeFi) space.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import HeadComponent from "@/components/HeadComponent.vue";

export default {
  name: 'HomeView',
  components: {
    HeadComponent

  }
}
</script>
