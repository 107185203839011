<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: "HeadComponent",
  computed: {
    ...mapState(['wallet_address']), // 映射 state
    connected() {
      if (this.wallet_address) {
        return this.wallet_address.substr(0, 6) + '...' + this.wallet_address.substr(-4)
      } else {
        return 'Connect Wallet'
      }
    }
  },
  data() {
    return {
      isVisible: false
    };
  },
  methods: {
    ...mapActions(['connect_wallet']), // 映射 actions
    onClickWallet: function () {
      this.isVisible = true;
    },
    onClickClose: function () {
      this.isVisible = false;
    },
    onClickMetamask: async function () {
      await this.connect_wallet('metamask');
      this.isVisible = false;
    },
    onClickOxk: async function () {
      await this.connect_wallet('okx');
      this.isVisible = false;
    },
    onClickGate: async function () {
      await this.connect_wallet('gate');
      this.isVisible = false;
    }
  }
}
</script>

<template>
  <div>
    <div class="head cur">
      <div class="wrap">
        <div class="logo">
          <router-link to="/">
            BNBPUMP
          </router-link>
        </div>
        <div class="nav">
          <ul>
            <li>
              <h2 class="h2tit">
                <router-link to="/">Home</router-link>
              </h2>
            </li>
            <li>
              <h2 class="h2tit">
                <router-link to="/launch">Launch</router-link>
              </h2>
            </li>
            <li>
              <h2 class="h2tit">
                <router-link to="/deploy">Deploy</router-link>
              </h2>
            </li>
          </ul>
        </div>
        <div class="right">
          <div class="link1">
            <a href="javascipt:;"><img src="images/li1.png" alt=""></a>
            <a href="javascipt:;"><img src="images/li2.png" alt=""></a>
          </div>
          <div class="link2">
            <a href="#"><img src="images/li3.png" alt=""></a>
          </div>
          <div class="link3">
            <a href="javascript:;" @click="onClickWallet">{{ connected }}</a>
          </div>
        </div>
      </div>
    </div>
    <div v-show="isVisible" class="alertmodel">
      <div class="shadow"></div>
      <div class="center">
        <div class="close" @click="onClickClose"></div>
        <div class="title">Choose wallet</div>
        <div class="link">
          <a href="javascript:;" @click="onClickOxk">
            <img src="images/ims1.png" alt="">
            <span>OKX WALLET</span>
          </a>
          <a href="javascript:;" @click="onClickMetamask">
            <img src="images/ims2.png" alt="">
            <span>METAMASK WALLET</span>
          </a>
          <a href="javascript:;" @click="onClickGate">
            <img src="images/ims3.png" alt="">
            <span>GATE WALLET</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Add your styles here */
</style>